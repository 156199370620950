<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '站点编辑' : '站点创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item label="名称" prop="name" :wrapper-col="wrapperCol">
        <a-input
          v-model="form.name"
          :disabled="isUpdate"
          :max-length="128"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="站点 URL" prop="url" :wrapper-col="wrapperCol">
        <a-input
          v-model="form.url"
          :disabled="isUpdate"
          :max-length="128"
          placeholder="以 http:// 或 https:// 开头"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item prop="groups" :wrapper-col="wrapperCol">
        <group-select v-model="form.groups" source-type="site"></group-select>
        <question-icon
          slot="label"
          title="所属群组"
          description="用于分组查询及告警配置"
        ></question-icon>
      </a-form-model-item>
      <a-form-model-item
        label="监控频率"
        prop="delay"
        :wrapper-col="wrapperCol"
      >
        <a-input
          v-model="form.delay"
          :disabled="isUpdate"
          :min="15"
          suffix="秒"
          type="number"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item prop="retries" :wrapper-col="wrapperCol">
        <question-icon
          slot="label"
          title="重试次数"
          description="请求重试次数"
        ></question-icon>
        <a-input
          v-model="form.retries"
          :disabled="isUpdate"
          :max="10"
          :min="1"
          type="number"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="请求超时"
        prop="timeout"
        :wrapper-col="wrapperCol"
      >
        <a-input
          v-model="form.timeout"
          :disabled="isUpdate"
          :max="60"
          :min="3"
          suffix="秒"
          type="number"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-alert message="200 ~ 299 的状态码视为请求成功" :show-icon="true"></a-alert>
      </a-form-model-item>
      <a-form-model-item prop="required" :wrapper-col="wrapperCol">
        <question-icon
          slot="label"
          title="响应内容"
          description="请求响应要求的内容，支持正则表达式"
        ></question-icon>
        <a-input
          v-model="form.required"
          :disabled="isUpdate"
          :max-length="128"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item prop="main" :wrapper-col="wrapperCol">
        <question-icon
          slot="label"
          title="主入口"
          description="开启日志统计供业务墙展示"
        ></question-icon>
        <a-switch v-model="form.main" :disabled="isUpdate"></a-switch>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { createSite, getSite, updateSite } from '@/api/site'
import QuestionIcon from '@/components/icon/QuestionIcon'
import GroupSelect from '@/components/select/GroupSelect'

export default {
  name: 'InputDrawer',
  components: {
    GroupSelect,
    QuestionIcon
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      // 避免抽屉打开不生效
      wrapperCol: {
        span: 20
      },
      form: {
        id: '',
        name: '',
        url: '',
        groups: undefined,
        delay: 60,
        retries: 3,
        timeout: 15,
        required: '',
        main: false
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        url: [
          {
            message: '请输入站点 URL',
            required: true,
            trigger: 'blur'
          },
          {
            message: '请输入正确的站点 URL',
            type: 'url',
            trigger: 'blur'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      loading: false
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getSite(id)
          .then(res => {
            const data = res.data
            this.form = {
              id: data.id,
              name: data.name,
              url: data.url,
              delay: data.delay,
              retries: data.retries,
              timeout: data.timeout,
              required: data.required,
              main: data.main,
              groups: data.groups
            }
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        url: '',
        groups: undefined,
        delay: 60,
        retries: 3,
        timeout: 15,
        required: '',
        main: false
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            main: this.form.main
          }
          if (this.form.groups) {
            params.group_ids = this.form.groups.map(group => group.id)
          }
          this.loading = true
          if (this.isUpdate) {
            updateSite(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            Object.assign(params, {
              name: this.form.name,
              url: this.form.url,
              delay: this.form.delay,
              retries: this.form.retries,
              timeout: this.form.timeout,
              required: this.form.required
            })
            createSite(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
